import { FunctionComponent, default as React, useMemo } from 'react';
import { HomeAccommodationJob, HotelJob, Job } from '../../../context/Route';
import CardListItem from '../../common/CardList/CardListItem';
import AddJob from './AddJob';
import ExtraDistance from './ExtraDistance';
import { AddWrapper } from './TourStopListLocationItem';
import { deleteJob } from './utils/delete-job';

import EnterHotel from './EnterHotel';
import { Col, Row } from 'antd';
import ItemComment from './ItemComment';
import ItemNote from './ItemNote';
import { setComment } from './utils/set-comment';
import { setNote } from './utils/set-note';

interface TourStopListHotelItemProps {
  job: HotelJob;
  next?: Job | HotelJob | HomeAccommodationJob;
  editing?: boolean;
  onChanged?: () => void;
}

const TourStopListHotelItem: FunctionComponent<TourStopListHotelItemProps> = ({
  job,
  next,
  editing,
  onChanged,
}) => {
  const handleSaveNote = async (value: string) => {
    await setNote(job, value);
    onChanged && onChanged();
  };

  const handleSaveComment = async (value: string) => {
    await setComment(job, value);
    onChanged && onChanged();
  };

  const renderExtra = () => {
    // if (editing)
    //   return <ExtraWrapper editing={true}>{renderAdd()}</ExtraWrapper>;
    if (next) return <ExtraDistance job={job} next={next} />;
    return false;
  };

  const subtitle = useMemo(() => {
    if (job.address) {
      return `${job.address.street} ${job.address.postalCode} ${job.address.city}`;
    }

    return job.nearByAddress;
  }, [job]);

  const title = useMemo(() => {
    return job.hotelName ?? 'Buchung offen';
  }, [job]);

  return (
    <CardListItem
      type='hotel'
      leading={'Ü'}
      title={title}
      subtitle={subtitle}
      extra={renderExtra()}
      action1={<EnterHotel job={job} onChanged={onChanged} />}
      onDelete={
        editing
          ? async () => {
              await deleteJob(job);
              onChanged && onChanged();
            }
          : undefined
      }
    >
      <Row>
        <Col xs={24} xl={12}>
          {/* <p>
            <ClockIcon className='comment' />
            {job.planHours?.toLocaleString() ?? '?'}h |{' '}
            {job.estimatedPrice?.toLocaleString() ?? '?'}€
          </p> */}
          <ItemComment job={job as unknown as Job} onSave={handleSaveComment} />
          <ItemNote job={job as unknown as Job} onSave={handleSaveNote} />
        </Col>
      </Row>
    </CardListItem>
  );
};

export default TourStopListHotelItem;
