import './Filter.scss';
import './Tours.scss';

import { chain, get, groupBy, identity, uniqBy } from 'lodash';
import React, { Component, Fragment } from 'react';

import { DateTime } from 'luxon';
import { NavLink } from 'react-router-dom';
import { Request } from '../../api/Request';
import { ReactComponent as CustomerIcon } from '../../assets/icons/customer.svg';
import { ReactComponent as LocationIcon } from '../../assets/icons/location.svg';
import { ReactComponent as StatusIcon } from '../../assets/icons/status.svg';
import { ReactComponent as TechnicianIcon } from '../../assets/icons/technician2.svg';
import { ReactComponent as ToursIcon } from '../../assets/icons/tours.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
import { ReactComponent as WrenchIcon } from '../../assets/icons/wrench.svg';
import DateFilter from './DateFilter';
import Filter from './Filter';
import TourMeta from './ListItem/TourMeta';
import ToursInfo from './ToursInfo';
import ToursTools from './ToursTools';

import { notification } from 'antd';
import numeral from 'numeral';
import qs from 'query-string';
import { withRouter } from 'react-router';
import renderLoading from '../SharesActions/renderLoading';

function TourStatus(props) {
  return (
    <div
      style={{
        marginTop: '0px',
      }}
    >
      <StatusIcon className='small' />
      <p className='header'>Status</p>
      <ul>
        <li className='status-text'>{get(props, 'item.statusText')}</li>
      </ul>
    </div>
  );
}

// Tour
function TourName(props) {
  const d = get(props, 'item.startDate');
  return (
    <Fragment>
      {d ? (
        <div
          style={{
            marginTop: '18px',
          }}
        >
          <ToursIcon className='small' />
          <p className='header'>{`Tour ${get(props, 'item.tag')}`}</p>
          <ul>
            <li>{DateTime.fromISO(d).setLocale('de').toFormat("'KW' WW")}</li>
          </ul>
        </div>
      ) : (
        false
      )}
    </Fragment>
  );
}

// Planer
function TourPlaner(props) {
  const d =
    get(props, 'item.createdBy.name') ??
    get(props, 'item.changedBy.name') ??
    'Planer gelöscht';
  return (
    <div
      style={{
        marginTop: '18px',
      }}
    >
      <UserIcon className='small' />
      <p className='header'>Planer</p>
      <ul>
        <li>{d}</li>
      </ul>
    </div>
  );
}

// Techniker
function TourTechnician(props) {
  const name = get(props, 'technicians[0].name', 'Techniker gelöscht');
  return (
    <div
      style={{
        marginTop: '0px',
      }}
    >
      <TechnicianIcon className='small' />
      <p className='header'>Techniker</p>
      <ul>
        <li>{name}</li>
      </ul>
    </div>
  );
}

function capitalize(s) {
  if (typeof s !== 'string' || !s) return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}

function CustomerChip({ customer }) {
  const { initials, name, _id } = customer;

  const style = {
    display: 'inline-block',
    borderRadius: '4px',
    padding: '4px 8px',
    fontSize: '10px',
    margin: '4px 4px 4px 0',
    backgroundColor: '#f1f1f1',
  };

  const pre = name.match(/\b\w{2}/g) || [];
  const shortedName =
    capitalize(pre.shift() || '') + capitalize(pre.pop() || '');

  return (
    <NavLink to={`/administration/customers/${_id}`} style={style}>
      {initials ?? shortedName ?? '...'}
    </NavLink>
  );
}

// Einsätze
function TourCustomers(props) {
  const customers = chain(props.jobs)
    .uniqBy((j) => j.location?.customer?._id)
    .map((j) => j?.location?.customer)
    .filter(identity)
    .value();

  return (
    <Fragment>
      <div
        style={{
          marginTop: '18px',
          minHeight: '89px',
        }}
      >
        <CustomerIcon className='small' />
        <p className='header'>Kunden</p>
        <div style={{ padding: '0 0 0 32px' }}>
          {customers.map((c) => (
            <CustomerChip key={c._id} customer={c} />
          ))}
        </div>
      </div>
    </Fragment>
  );
}

// Tag
function TourDays(props) {
  const d = groupBy(props.jobs, (j) => DateTime.fromISO(j.planedAt).weekday);
  const s = { float: 'left', width: '22px', color: '#a1a1a1' };
  const sa = { float: 'left', width: '22px', color: '#000' };
  return (
    <Fragment>
      <div
        style={
          {
            // marginTop: '18px',
          }
        }
      >
        <WrenchIcon className='small' />
        <p className='header'>Einsatztage</p>
        <ul>
          <li style={d[1] ? sa : s}>Mo</li>
          <li style={d[2] ? sa : s}>Di</li>
          <li style={d[3] ? sa : s}>Mi</li>
          <li style={d[4] ? sa : s}>Do</li>
          <li style={d[5] ? sa : s}>Fr</li>
          <li style={d[6] ? sa : s}>Sa</li>
          <li style={d[0] ? sa : s}>So</li>
          <li style={{ width: '100px' }}>| {Object.keys(d).length} Tage</li>
        </ul>
      </div>
    </Fragment>
  );
}

// Locations
function TourLocations(props) {
  const locations = chain(props.jobs)
    .map((j) => (j.location ? j.location._id : undefined))
    .filter(identity)
    .uniq()
    .value();

  return (
    <Fragment>
      <div
        style={{
          marginTop: '18px',
        }}
      >
        <LocationIcon className='small' />
        <p className='header'>Standorte</p>
        <ul>
          <li>{locations.length} Standorte</li>
        </ul>
      </div>
    </Fragment>
  );
}

// ** View ** //

const setQueryStringWithoutPageReload = (qsValue) => {
  const oldurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${window.location.search}`;
  const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${qsValue}`;
  if (oldurl !== newurl) window.history.pushState({ path: newurl }, '', newurl);
};

const defaultOptions = {
  limit: 2500,
  page: 0,
  status: undefined,
  dateRange: {
    startDate: DateTime.fromJSDate(new Date()).startOf('week').toJSDate(),
    endDate: DateTime.fromJSDate(new Date()).endOf('week').toJSDate(),
  },
};

class ToursList extends Component {
  state = {
    loading: true,
    loadingFilters: true,
    items: [],
    options: defaultOptions,
    filterValues: {
      stati: [],
      locations: [],
      technicians: [],
      customers: [],
    },
  };

  constructor(props) {
    super(props);

    this.state.options = {
      ...defaultOptions,
      ...this.parseQueryStringToOptions(),
    };

    this.updateQueryString();
    this.setOptions = this.setOptions.bind(this);
  }

  componentDidMount() {
    this.loadDataForFilters();
    this.loadData();
  }

  updateQueryString() {
    const startDate = DateTime.fromJSDate(
      get(this.state, 'options.dateRange.startDate')
    );
    const endDate = DateTime.fromJSDate(
      get(this.state, 'options.dateRange.endDate')
    );
    const d = {
      technicians: this.state.options.technicians || null,
      locations: this.state.options.locations || null,
      customers: this.state.options.customers || null,
      startDate: startDate.isValid ? startDate.toMillis() : null,
      endDate: endDate.isValid ? endDate.toMillis() : null,
      limit: this.state.options.limit || defaultOptions.limit,
      status: this.state.options.status || defaultOptions.status,
      page: this.state.options.page || defaultOptions.page,
    };
    const query = qs.stringify(d);
    setQueryStringWithoutPageReload(query);
  }

  parseQueryStringToOptions() {
    const r = qs.parse(this.props.location.search);
    const startDate = r.startDate && DateTime.fromMillis(Number(r.startDate));
    const endDate = r.endDate && DateTime.fromMillis(Number(r.endDate));
    const hasDate =
      startDate && startDate.isValid && endDate && endDate.isValid;
    return {
      technicians: r.technicians || null,
      locations: r.locations || null,
      customers: r.customers || null,
      limit: r.limit || defaultOptions.limit,
      status: r.status || defaultOptions.status,
      page: r.page || defaultOptions.page,
      ...(hasDate
        ? {
            dateRange: {
              startDate: startDate.toJSDate(),
              endDate: endDate.toJSDate(),
            },
          }
        : {}),
    };
  }

  async loadData() {
    const params = {
      limit: this.state.options.limit,
      status: this.state.options.status,
      page: this.state.options.page,
      start: this.state.options.dateRange.startDate.toISOString(),
      end: this.state.options.dateRange.endDate.toISOString(),
      technicians: this.state.options.technicians,
      customers: this.state.options.customers,
      locations: this.state.options.locations,
    };
    const { items, total } = await Request.list('tours', params);
    this.setState({
      items,
      allItemsCount: total,
      loading: false,
    });
  }

  async loadDataForFilters() {
    const [locations, stati, technicians] = await Promise.all([
      Request.list('locationsWithJob', {
        sort: 'name',
        desc: false,
        page: 0,
        limit: 2500,
        startDate: this.state.options.dateRange.startDate.toISOString(),
        endDate: this.state.options.dateRange.endDate.toISOString(),
        slim: true,
      }).then(({ items }) => items),
      Request.list('enums/tour/status'),
      Request.list('technicians', { limit: 25000, slim: true }).then(
        ({ items }) =>
          items.map((l) => {
            return { key: l._id, value: l._id, text: l.name };
          })
      ),
    ]);

    const uniqLocations = uniqBy(locations, '_id');

    const mappedLocations = uniqLocations.map((l) => {
      return { key: l._id, value: l._id, text: `${l.name} [${l.tag}] ` };
    });

    const mappedCustomers = uniqBy(
      uniqLocations.map((l) => {
        return {
          key: l.customer._id,
          value: l.customer._id,
          text: l.customer.name,
        };
      }),
      'value'
    );

    this.setState({
      loadingFilters: false,
      filterValues: {
        locations: mappedLocations,
        customers: mappedCustomers,
        stati,
        technicians,
      },
    });
  }

  setOptions(opt) {
    this.setState(
      {
        loading: true,
        options: {
          ...this.state.options,
          ...opt,
        },
      },
      () => {
        this.updateQueryString();
        this.loadData();
      }
    );
  }

  renderEmpty() {
    return (
      <div className='col col-12 col-xl-6'>
        <p>Es wurden noch keine Touren geplant für diesen Zeitraum.</p>
      </div>
    );
  }

  renderFilter() {
    const {
      loadingFilters,
      options,
      filterValues: { locations, technicians, customers, stati },
    } = this.state;
    if (loadingFilters) return renderLoading(20, 'small-loader');
    return (
      <Fragment>
        <DateFilter
          text='Zeitraum'
          range={{
            startDate: options.dateRange.startDate,
            endDate: options.dateRange.endDate,
          }}
          onChange={(value) => {
            this.setOptions({
              dateRange: { startDate: value.startDate, endDate: value.endDate },
            });
          }}
        />
        <Filter
          name='status'
          options={options}
          values={stati}
          onChange={this.setOptions}
        >
          Status
        </Filter>
        <Filter
          name='technicians'
          options={options}
          values={technicians}
          onChange={this.setOptions}
        >
          Techniker
        </Filter>
        <Filter
          name='customers'
          options={options}
          values={customers}
          onChange={this.setOptions}
        >
          Kunden
        </Filter>
        <Filter
          name='locations'
          options={options}
          values={locations}
          onChange={this.setOptions}
        >
          Standorte
        </Filter>
      </Fragment>
    );
  }

  renderItems() {
    const { loadingFilters, loading, items } = this.state;
    if (loading || loadingFilters) return renderLoading();
    if (!items || items.length === 0) return this.renderEmpty();
    return items.map((item) => this.renderItem(item));
  }

  shortCurrency(value, n) {
    const f = value < 10000 ? n || '0,0' : '0.0a';
    return numeral(value).format(f);
  }

  renderItem(item) {
    let statusClass = item.status && item.status.split(':')[2];

    const jobs = item.jobs.filter((job) => !job.isHotel);
    const technicians = [item.technician];
    return (
      <div key={item._id} className='col col-12 col-xl-6'>
        <NavLink to={`tours/${item._id}${window.location.search}`}>
          <div className={`tour status-${statusClass}`}>
            <div className='row'>
              <div className='col col-12 col-sm-3'>
                <TourTechnician technicians={technicians} />
                <TourName item={item} />
                <TourPlaner item={item} />
              </div>
              <div className='col col-12 col-sm-4'>
                <TourStatus item={item} />
                <TourMeta item={item} />
              </div>
              <div className='col col-12 col-sm-5'>
                <TourDays jobs={jobs} />
                <TourLocations jobs={jobs} />
                <TourCustomers jobs={jobs} />
              </div>
            </div>
          </div>
        </NavLink>
      </div>
    );
  }

  async jumpToTour(tag) {
    try {
      const { id } = await Request.get(
        'tour-by-tag',
        tag,
        undefined,
        undefined,
        false
      );

      this.props.history.push(`/tours/${id}`);
    } catch (error) {
      notification.error({
        message: 'Tour nicht gefunden!',
        description: 'Es konnte keine Tour mit dieser Nummer ermittelt werden.',
      });
    }
  }

  render() {
    // console.log(this.state.options);
    return (
      <div className='tours container-inner'>
        <div className='page-header row justify-content-between'>
          <div className='col col-6'>
            <h1 className='page-title'>Touren</h1>
            <div className='page-subtitle'>{this.renderFilter()}</div>
          </div>
          <div className='col col-2 tour-shortcut'>
            <div>
              <span>Tour-ID: </span>
              <input
                type='text'
                placeholder='####'
                name='someName'
                id='someId'
                maxLength={4}
                minLength={4}
                max={9999}
                min={1000}
                required='required'
                pattern='^([0-9]){4}$'
                onKeyUp={(e) => {
                  const regex = /^([0-9]){4}$/gm;
                  if (e.target.value && regex.exec(e.target.value) !== null) {
                    this.jumpToTour(e.target.value);
                  }
                }}
              />
            </div>
          </div>
        </div>
        {!this.state.loading && (
          <>
            <ToursTools tours={this.state.items} />
            <ToursInfo tours={this.state.items} />
          </>
        )}
        <div className='row page-content'>{this.renderItems()}</div>
      </div>
    );
  }
}

export default withRouter(ToursList);
