import React, { FunctionComponent } from 'react';
import DateAction from '../../common/CardList/Actions/DateAction';
import { DateTime } from 'luxon';
import { Job, HotelJob } from '../../../context/Route';
import { setPlanedAt } from './utils/set-planedAt';

import { Modal } from 'antd';
const { confirm } = Modal;

interface PlanedAtProps {
  job: Job | HotelJob;
  readonly?: boolean;
  isNotified?: boolean;
  onChanged?: () => void;
}

const PlanedAt: FunctionComponent<PlanedAtProps> = ({
  readonly,
  job,
  onChanged,
}) => {
  const d =
    typeof job.planedAt === 'string'
      ? DateTime.fromISO(job.planedAt)
      : DateTime.fromJSDate(job.planedAt ?? new Date());

  // const format = job.fixed ? 'datetime' : 'date';
  const format = 'datetime';
  const date = d.toJSDate();

  function showConfirm() {
    return new Promise((resolve) => {
      confirm({
        title: 'Achtung',
        content: (
          <p>
            Der Kunde wurde über die bestehtende Planung informiert. Möchten Sie
            die Planung wirklich ändern?
          </p>
        ),
        onOk() {
          resolve(true);
        },
        onCancel() {
          resolve(undefined);
        },
      });
    });
  }

  const handleChange = async (date: Date, fixed: boolean) => {
    const res = await showConfirm();
    if (res === true) {
      debugger;
      await setPlanedAt(job as Job, date, fixed);
      onChanged && onChanged();
    }
  };

  return job.planedAt ? (
    <DateAction
      noBackground
      keepWeek
      format={format}
      value={date}
      onChange={
        readonly ? undefined : (res) => handleChange(res.date, res.fixed)
      }
    />
  ) : (
    <></>
  );
};

export default PlanedAt;
