import { Col, Row } from 'antd';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';
import { ClipLoader } from 'react-spinners';

export interface CardListItemProps {
  type?: string;
  id?: string;
  leading?: string;
  title: string;
  subtitle?: string;
  link?: string;
  alert?: boolean;
  action1?: React.ReactNode;
  action2?: React.ReactNode;
  action3?: React.ReactNode;
  action4?: React.ReactNode;
  children?: React.ReactNode;
  extra?: React.ReactNode;
  paddingLeft?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onDelete?: () => void;
}

const Wrapper = styled.div<{ alert?: boolean }>`
  background-color: rgba(255, 255, 255, 1);
  border-radius: 3px;
  border: 2px solid rgba(203, 199, 215, 1);
  opacity: 1;
  outline: ${({ alert }) => (alert ? '2px solid red' : 'none')};
`;

const Header = styled.div`
  border-radius: 3px 3px 0px 0px;
  opacity: 1;
  background-color: inherit;
  padding: 12px 22px;
  min-height: 62px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  > .ant-row {
    width: calc(100% + 26px);
  }
`;

const Leading = styled.div`
  border-radius: 3px;
  border: 1px solid rgba(239, 239, 239, 1);
  opacity: 1;
  background-color: rgba(203, 199, 215, 1);
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  font-weight: 500;
  font-style: Medium;
  letter-spacing: 0px;
  margin: 0;
  user-select: none;
`;

const TitleWrapper = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  cursor: pointer;
`;

const Title = styled.div`
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  font-weight: 500;
  font-style: Medium;
  line-height: 1;
  margin: 0 0 2px 0;
`;

const Subtitle = styled.div`
  color: rgba(94, 94, 94, 1);
  font-size: 12px;
  font-weight: 500;
  font-style: Medium;
  line-height: 1;
  margin: 2px 0 0 0;
`;

const Content = styled.div<{
  paddingLeft: boolean;
}>`
  min-height: 100px;
  padding: 5px 12px 5px
    ${({ paddingLeft }) => (paddingLeft !== false ? '26px' : '22px')};
  p {
    color: rgba(0, 0, 0, 1);
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    margin: 8px 0;
    svg {
      opacity: 1;
      width: 12px;
      height: 12px;
      margin: 1px 10px 1px 0px;
      position: relative;
      top: -1px;
      g,
      p,
      path {
        fill: rgba(94, 94, 94, 1);
      }
    }
  }

  @media (min-width: 1200px) {
    padding: 5px 12px 5px
      ${({ paddingLeft }) => (paddingLeft !== false ? '72px' : '22px')};
  }
`;

const DeleteAction = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 4px;
  svg {
    width: 16px;
    height: 16px;
    margin: 0;
    path {
      fill: rgba(94, 94, 94, 1);
    }
  }
  :hover {
    svg,
    svg path {
      fill: rgba(255, 0, 0, 1);
    }
  }
`;

const CardListItem: FunctionComponent<CardListItemProps> = ({
  type,
  id,
  children,
  alert,
  extra,
  title,
  subtitle,
  leading,
  action1,
  action2,
  action3,
  action4,
  paddingLeft,
  onMouseEnter,
  onMouseLeave,
  onDelete,
  link,
}) => {
  const [deleting, setDeleting] = React.useState<boolean>(false);

  const renderAction = (action: React.ReactNode) => {
    if (action) {
      return (
        <Col xs={24} md={12} lg={6}>
          {action}
        </Col>
      );
    }
    return false;
  };

  return (
    <>
      <Wrapper
        id={id}
        attr-type={type}
        className={`item item-${type}`}
        alert={alert}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Header className='header'>
          <Row gutter={[26, 12]} justify='start' align='middle'>
            <Col xs={1}>{leading ? <Leading>{leading}</Leading> : false}</Col>
            <TitleWrapper
              xs={23}
              xl={8}
              onClick={() => {
                if (link) {
                  window?.open(link, '_blank')?.focus();
                }
              }}
            >
              <Title>{title}</Title>
              {subtitle ? <Subtitle>{subtitle}</Subtitle> : false}
            </TitleWrapper>
            <Col xs={23} xl={15}>
              <Row gutter={[26, 12]} justify='end' align='middle'>
                {renderAction(action1)}
                {renderAction(action2)}
                {renderAction(action3)}
                {renderAction(action4)}
              </Row>
            </Col>
            {onDelete && (
              <Col xs={1}>
                <DeleteAction
                  onClick={async () => {
                    setDeleting(true);
                    await onDelete();
                    setDeleting(false);
                  }}
                >
                  {deleting && (
                    <ClipLoader
                      size={'16px'}
                      color={'#ff0000'}
                      loading={true}
                    />
                  )}
                  {!deleting && <TrashIcon />}
                </DeleteAction>
              </Col>
            )}
          </Row>
        </Header>
        {children && (
          <Content paddingLeft={paddingLeft ?? true}>{children}</Content>
        )}
      </Wrapper>
      {extra}
    </>
  );
};

export default CardListItem;
